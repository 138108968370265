<template>
  <div class="api-key-management childpool-wrap">
    <input id="cpy" type="hidden" />
    <CModal id="createModal" :show.sync="createKeyModal" size="sm" :centered="true">
      <template #header>
        <CButtonClose class="close-modal" @click="createKeyModal = false" />
      </template>
      <div @keyup.enter="createKey">
        <div class="pb-1">{{$t("API_KEY_MANAGEMENT_PAGE_LABEL_NAME")}}</div>
        <CInput v-model="apiName" :placeholder="$t('API_KEY_MANAGEMENT_PAGE_PLACEHOLDER_ENTER_NAME')"/>
      </div>
      <template #footer>
        <CButton @click="createKey" color="primary">{{$t('API_KEY_MANAGEMENT_PAGE_BUTTON_CREATE_KEY')}}</CButton>
      </template>
    </CModal>
    <CModal id="deleteModal" :show.sync="warningModal" :centered="true">
      <template #header>
        <CButtonClose class="close-modal" @click="warningModal = false" />
      </template>
      <div class="">
        <div class="pb-4 tit-dele">
          <!-- <CIcon width="48px" class="deleteIcon" :content="cilTrash" /> -->
          <h4>{{$t("API_KEY_MANAGEMENT_PAGE_LABEL_MODAL_DELETE_ACCOUNT")}}</h4>
        </div>
        <div class="text-modal" v-html="$t('API_KEY_MANAGEMENT_PAGE_CONTENT_MODAL_DELETE_ACCOUNT', {name: nameToDelete})"></div>
      </div>
      <template #footer>
        <CButton
          class="cancel-btn"
          @click="warningModal = false"
          color="dark"
          variant="outline"
        >{{$t("API_KEY_MANAGEMENT_PAGE_BUTTON_MODAL_CANCEL")}}</CButton>
        <CButton @click="deleteKey" class="delete-btn">{{$t("API_KEY_MANAGEMENT_PAGE_BUTTON_MODAL_DELETE_KEY")}}</CButton>
      </template>
    </CModal>
    <CCard>
      <CCardBody>
        <div class="text-right mb-2">
          <CButton class="btn-add-key" @click="createKeyModal = true" v-if="this.hasAdd === true">
            <!-- <span class="pr-1">
              <CIcon :content="cilMedicalCross" />
            </span> -->
            <span>+ {{$t("API_KEY_MANAGEMENT_PAGE_BUTTON_GENERATE_NEW_KEY")}}</span>
          </CButton>
        </div>
        <CDataTable
          class="mb-0"
          hover
          outlined
          striped
          :items="apiKeys"
          :fields="tableHeaders"
          head-color="light"
          no-sorting
        >
          <td slot="order" class="text-center" slot-scope="{ item, index }">{{ index + 1 }}</td>
          <td slot="apiKey" slot-scope="{ item }">
            <span>{{ item.api_key }}</span>
            <span class="pl-4" @click="copy(item.api_key, 'API Key')">
              <CIcon :content="cilCopy" />
            </span>
          </td>
          <td slot="secretKey" slot-scope="{ item }">
            <span class="secret">*****</span>
            <span class="pl-4" @click="copy(item.secret_key, 'Secret Key')">
              <CIcon :content="cilCopy" />
            </span>
          </td>
           <td slot="status" slot-scope="{ item }">
            <span class="status-icon"><i  :class="item.status=='CONNECTED'?'far fa-check-circle':'far fa-times-circle'"></i></span>
          </td>
          <td class="text-center" slot="action" slot-scope="{ item, index }" v-if="hasDel === true">
            <span @click="openDeleteModal(index, item.name)">
              <CIcon width="24px" class="deleteIcon" :content="cilTrash" />
            </span>
          </td>
        </CDataTable>
        <div>
          <CPagination
            v-if="pages>1"
            :activePage.sync="activePage"
            :pages="pages"
            align="end"
            @update:activePage="pushPage"
          />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { cilMedicalCross, cilCopy, cilTrash } from "@coreui/icons";
import endpoints from "@/constants/endpoints";
import { mapState} from "vuex";
import responseHelper from '@/utils/response-helper';
export default {
  name: "ApiKeyManagement",
  data() {
    return {
      nameToDelete: '',
      cilMedicalCross,
      cilCopy,
      cilTrash,
      warningModal: false,
      createKeyModal: false,
      apiName: "",
      tableHeaders: [
        { key: "order", label: "#", _classes: "text-center" },
        { key: "name", label: this.$t('API_KEY_MANAGEMENT_PAGE_LABEL_NAME') },
        { key: "apiKey", label: this.$t("API_KEY_MANAGEMENT_PAGE_LABEL_API_KEY") },
        { key: "secretKey", label: this.$t("API_KEY_MANAGEMENT_PAGE_LABEL_SECRET_KEY") },
        { key: "status", label: this.$t("API_KEY_MANAGEMENT_PAGE_LABEL_STATUS") },
        { key: "action", label: this.$t("API_KEY_MANAGEMENT_PAGE_LABEL_ACTION"), _classes: "text-center" }
      ],
      apiKeys: [],
      activePage: 1,
      partnerID: "",
      limit: 5,
      pages: 0,
      selectedKey: "",
      hasAdd: false,
      hasDel: false
    };
  },
  computed: {
    ...mapState({
       userPermission: state => state.auth.userPermission,
    }),
    offset() {
      return (this.activePage - 1) * this.limit;
    }
  },
  async mounted() {
    this.partnerID = this.$route.params.id;
    let apiKeys = await this.getListAPIKeys();
    if (!apiKeys) {
      return;
    }

    this.apiKeys = apiKeys.items.slice();
    let totalItems = parseInt(apiKeys.total);
    this.pages = parseInt(Math.ceil(totalItems / this.limit));
    await this.checkPermission();
  },
  methods: {
    async getListAPIKeys() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset
        };

        let result = await this.$http.get(
          endpoints.getAPIKeys(this.partnerID),
          {
            params: params
          }
        );
        console.log("result: ", result);
        return result.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    async createKey() {
      this.createKeyModal = false;
      if (!this.apiName) {
        return;
      }
      console.log("Create API: ", this.apiName);
      try {
        const data = {
          name: this.apiName
        };
        let res = await this.$http.post(
          endpoints.createAPIKey(this.partnerID),
          data,
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );
        this.pushPage();
        this.$notify({
          group: "notifications",
          type: "success",
          title: this.$t("API_KEY_MANAGEMENT_PAGE_TITLE_NOTIFY_SUCCESS"),
          text: this.$t("API_KEY_MANAGEMENT_PAGE_TEXT_NOTIFY_CREATED_SUCCESS"),
          duration: 1000
        });
      } catch (err) {
        console.log(err);
        this.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("API_KEY_MANAGEMENT_PAGE_TITLE_NOTIFY_ERROR"),
          text: responseHelper.getErrorMessage(err),
          duration: 1000
        });
      }
      this.apiName = '';
    },

    openDeleteModal(key,name) {
      this.warningModal = true;
      console.log(key);
      this.selectedKey = key;
      this.nameToDelete = name;
    },

    async deleteKey() {
      this.warningModal = false;
      try {
        let result = await this.$http.delete(
          endpoints.deleteAPIKey(
            this.partnerID,
            this.apiKeys[this.selectedKey].id
          )
        );

        this.pushPage();

        this.$notify({
          group: "notifications",
          type: "success",
          title: this.$t("API_KEY_MANAGEMENT_PAGE_TITLE_NOTIFY_SUCCESS"),
          text: this.$t("API_KEY_MANAGEMENT_PAGE_TEXT_NOTIFY_DELETED_SUCCESS"),
          duration: 1000
        });
        // call list
      } catch (err) {
        console.log(err);
        this.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("API_KEY_MANAGEMENT_PAGE_TITLE_NOTIFY_ERROR"),
          text: responseHelper.getErrorMessage(err),
          duration: 1000
        });
      }
    },

    async pushPage() {
      let apis = await this.getListAPIKeys();
      if (!apis) {
        return;
      }
      let totalItems = parseInt(apis.total);
      this.pages = parseInt(Math.ceil(totalItems / this.limit));
      this.apiKeys = apis.items.slice();
    },
    copy(item, subtit) {
      const element = document.querySelector("#cpy");
      element.setAttribute("type", "text");
      element.value = item;
      element.select();
      try {
        const successful = document.execCommand("copy");
        const msg = successful ? this.$t("API_KEY_MANAGEMENT_PAGE_MSG_SUCCESSFUL") : this.$t("API_KEY_MANAGEMENT_PAGE_MSG_UNSUCCESSFUL");
        this.$notify({
          group: "notifications",
          type: "success",
          title: this.$t("API_KEY_MANAGEMENT_PAGE_TITLE_NOTIFY_SUCCESS"),
          text: this.$t("API_KEY_MANAGEMENT_PAGE_TEXT_NOTIFY_COPY_SUCCESS", {
            subtit: subtit, msg: msg
          }),
        });
      } catch (err) {
        this.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("API_KEY_MANAGEMENT_PAGE_TITLE_NOTIFY_ERROR"),
          text: responseHelper.getErrorMessage(err),
        });
      }
      element.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    checkPermission(){
       if(this.userPermission.length > 0){
          this.userPermission.forEach(items =>{
             if(items.name === 'CREATE_KEY_PARTNER'){
                this.hasAdd = true
             }
              if(items.name === 'DELETE_KEY_PARTNER'){
                  this.hasDel = true
              }
          })
       }
    }
  }
};
</script>
<style lang="scss" scoped>
.api-key-management /deep/ {
  $green: #4dc1a1;
  // background-color: transparent;
  .secret {
    user-select: none;
  }
  .modal-header {
    border: none;
    padding: 0 8px;
  }

  .modal-footer {
    border: none;
    justify-content: center;
  }

  .close-modal {
    position: absolute;
    right: 8px;
    top: 4px;
    z-index: 1;
  }

  #createModal {
    .form-group {
      margin-bottom: 0;
    }
  }

  // #deleteModal {
  //   .text-modal {
  //     font-size: 18px;
  //   }
  // }
  .deleteIcon {
    fill: #ff0000;
  }
  .delete-btn {
    background-color: #ff0000;
    color: #ffffff;
  }
  .cancel-btn {
    padding-left: 24px;
    padding-right: 24px;
  }
  .c-icon {
    cursor: pointer;
  }
  .status-icon {
    margin-left:10px;
    i {
      font-size: 24px;
    }
    .fa-check-circle {
      color:#078C4F;
    }
    .fa-times-circle {
      color:#D31427;

    }
  }
  .btn-add-key{
    background: #3B4B7E;
    border: none;
    border-radius: 32px;
    height: 32px;
    padding: 0 30px;
    span{
      color: #FFFFFF;
      // font-family: "SF UI Display";
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-align: center;
    }
  }
.modal-content{
    width: 85%; 
    .modal-header {
      border: none;
      padding: 0 8px;
      display: none;
    }
    .text-modal {
      strong{
        color: #3A4A7F;
        font-weight: 500;
      }
    }
    .modal-footer {
      border: none;
      justify-content: center;
      .delete-btn {
        background-color: #FF5555;
        color: #ffffff;
        width: 150px;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
      }
      .cancel-btn {
        width: 150px;
        border: 2px solid #CBCBCB;
        color: #657187;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
        &:hover{
          background-color: #fff
        }
      }
    }
}
}
</style>
